import React, { FunctionComponent } from "react";
import styled from "styled-components";
import {
  Chip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { colors } from "merchant-core";
import { useStyles } from "../hooks/useStyles";
import LockIcon from "./Icons/LockIcon";

export type StepId =
| "accountDetails"
| "businessInfo"
| "linkBankAccount"
| "inReview"
| "msaSigned"
| "integrationsTeam"
| "addWidget"
| "addCheckout";

export type ChecklistItemProps = {
  'data-testid'?: StepId;
  primaryText: string;
  estimatedTime?: string;
  hidden?: boolean;
  checked?: boolean;
  disabled?: boolean;
  inReview?: boolean;
  href?: string;
  tooltip?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
};

const StyledCheckedIcon = styled(CheckCircleIcon)`
  color: ${colors.PURPLE_DARKEST};
`;

const StyledCircleIcon = styled(CircleOutlinedIcon)`
  color: ${colors.PURPLE_DARKEST};
`;

const StyledChip = styled(Chip)({
  "margin-left": "1rem",
});

export const ChecklistItem: FunctionComponent<ChecklistItemProps> = ({
  primaryText,
  estimatedTime,
  hidden,
  checked,
  disabled,
  inReview,
  href,
  tooltip,
  icon,
  "data-testid": testid
}): JSX.Element => {
  const { isLightMode } = useStyles();
  const { t } = useTranslation("common");

  if (hidden) {
    return <></>;
  }

  const button = (
    <ListItemButton disabled={!checked && disabled} disableRipple>
      <ListItemIcon>
        {checked ? (
          <StyledCheckedIcon />
        ) : icon !== undefined ? (
          icon
        ) : (
          <StyledCircleIcon />
        )}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography
            variant="body2"
            color={isLightMode ? colors.PURPLE_DARKEST : colors.PURPLE_LIGHTEST}
          >
            {primaryText}
          </Typography>
        }
      />

      {disabled && !checked && <LockIcon />}

      {!checked && (inReview || estimatedTime) && (
        <StyledChip
          variant="outlined"
          icon={inReview ? <InfoIcon /> : undefined}
          sx={{
            border: "none",
            borderRadius: "7px",
            backgroundColor: inReview
              ? colors.TOOLTIP_ORANGE
              : disabled
                ? colors.GREY
                : colors.PURPLE_MEDIUM,
            color: colors.PURE_WHITE,
          }}
          label={
            <Stack direction="row">
              {inReview ? t("in-review") : estimatedTime}
              {tooltip && (
                <Tooltip title={tooltip} placement="top" arrow>
                  <InfoIcon sx={{ marginLeft: "2px", height: "20px" }} />
                </Tooltip>
              )}
            </Stack>
          }
        />
      )}
    </ListItemButton>
  );

  const decoratedButton =
    !disabled && href ? (
      <Link href={href || ""} passHref style={{ width: "100%" }}>
        {button}
      </Link>
    ) : (
      button
    );

  return (
    <ListItem key={primaryText} data-testid={testid ?? primaryText}>
      {decoratedButton}
    </ListItem>
  );
};
